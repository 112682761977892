<template>
  <div>
    <div v-if="videoPreview" @click="closePreview" class="preview-screen video-fade">
      <video class="video-preview" @click.stop autoplay controls>
        <source :src="videoPreview" type="video/mp4">
      </video>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="cui__utils__heading mb-0">
          <strong>Video Creatives</strong>
        </div>
      </div>
      <div class="card-body">
        <CreativesDraggable type="video" :fileList="fileList" :receivedCreatives="receivedCreatives" />
        <div class="msg-box msg-box-flex">
          <a-icon class="mx-2" style="fontSize: 200%; opacity: 70%; color: #5481e1;" type="info-circle" />
          <p class="mt-3 ml-2">
            Upload <strong>MP4</strong> or <strong>WEBM</strong> file only.
          </p>
        </div>
      </div>
    </div>
    <b-container class="overflow-auto" fluid>
      <!-- Ad Interface controls -->
      <a-row class="my-2">
        <a-col :xl="4" :md="12" :xs="24">
          <!-- <a-select
            allowClear
            placeholder="Campaign"
            style="width: 100%"
            :showSearch="true"
            :filterOption="true"
            optionFilterProp="children"
            @change="actionGetCreatives"
          >
            <a-select-option v-for="obj in ortbCampaign.list" :key="obj.Id">{{obj.Name}}</a-select-option>
          </a-select> -->
        </a-col>
      </a-row>
      <b-row>
        <b-col class="my-1" md="6">
          <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
            <perPageSelect v-model="perPage" :storageKey="'ortb-video-library'" :pageOptions="pageOptions" />
            <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
            <columnSelect :storageKey="'display-videos-columns'" :options="fields"></columnSelect>
            <!-- <CreateButton module="display" target="bannerad" /> -->
          </b-form-group>
        </b-col>
        <b-col class="my-1 d-flex flex-row-reverse" md="6">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table :bordered="true" :current-page="currentPage" :fields="filteredFields" :hover="true"
        :items="receivedCreatives" :per-page="perPage" :small="true" :striped="true" show-empty stacked="md" class="mt-3">
        <template #cell(Name)="data">
          <div style="max-width: 200px; min-width: 140px; word-wrap: break-word;" class="d-block d-sm-none">
            {{ data.item.Name }}
          </div>
          <div class="d-none d-sm-block">
            {{ data.item.Name }}
          </div>
        </template>
        <template #cell(Dimensions)="data">
          <div class="">{{ data.item.Width }}x{{ data.item.Height }}</div>
        </template>
        <template #cell(Ratio)="data">
          {{ data.item.WidthRatio }}:{{ data.item.HeightRatio }}
        </template>
        <template #cell(FileSize)="data">
          {{ bytesToSize(data.item.FileSize) }}
        </template>
        <template #cell(Type)="data">
          {{ creativeType(data.item.Mime) }}
        </template>
        <template #cell(Duration)="data">
          {{ convertDuration(data.item.Duration) }}
        </template>
        <template #cell(Framerate)="data">
          {{ data.item.Framerate }} FPS
        </template>
        <template #cell(Bitrate)="data">
          {{ data.item.Bitrate }}&nbsp;kbps
        </template>
        <template #cell(MediaRating)="data">
          <!-- {{ convertMediaRating(data.item.MediaRating) }} -->
          {{ convertMediaRating(data.item.MediaRating) }}
        </template>
        <template #cell(Preview)="data">
          <a-icon @click="videoPreview = link + data.item.DownloadUrl" class="cpointer" style="fontSize: 140%"
            type="play-circle" />
        </template>
        <template #cell(actions)="data">
          <router-link class="add-button" @click.stop
            :to="{ name: 'display-creative-update', params: { id: data.item.Id }, }">
            <b-button class="btn-info mr-1" size="sm" @click="editCreative(data.item.Id)"><i
                class="fa fa-edit"></i></b-button>
          </router-link>
        </template>
      </b-table>
      <b-row>
        <b-col class="my-1" md="6">
          <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
              perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
        </b-col>
        <b-col class="my-1 d-flex flex-row-reverse" md="6">
          <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" class="my-0"></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { createCreative, updateCreative, getCreatives } from '@/api/display/creative'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'

export default {
  props: ['fileList'],
  components: {
    columnSelect,
    CreativesDraggable: () => import('@/components/custom/creatives/creativesDraggable/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      fileListValue: this.fileList,
      videoPreviewValue: this.videoPreview,
      videoPreview: false,
      link: process.env.NODE_ENV === 'production' ? window.location.origin : 'https://dev.rtb.me',
      receivedCreatives: [],
      fields: [
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'Preview',
          label: 'Preview',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width: 50px; max-height:200px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          class: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width: 50px;',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: false,
          tdClass: 'align-middle',
          // thStyle: 'max-height:200px',
          thStyle: 'width: 250px',
        },
        {
          key: 'Dimensions',
          label: 'Dimensions',
          show: true,
          headerTitle: 'Dimensions',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Ratio',
          label: 'Ratio',
          show: true,
          headerTitle: 'Ratio',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Duration',
          label: 'Duration',
          show: true,
          headerTitle: 'Duration',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Framerate',
          label: 'Framerate',
          show: true,
          headerTitle: 'Framerate',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Codec',
          label: 'Codec',
          show: true,
          headerTitle: 'Codec',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Bitrate',
          label: 'Bitrate',
          show: true,
          headerTitle: 'Bitrate',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'FileSize',
          label: 'Size',
          show: true,
          headerTitle: 'Size',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Type',
          label: 'Type',
          show: true,
          headerTitle: 'Type',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'MediaRating',
          label: 'Media Rating',
          show: true,
          headerTitle: 'Media Rating',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width:200px; max-height:200px;',
        },
      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['creative', 'settings']),
    ...mapGetters('creative', ['getCreatives']),
    totalRows() {
      return this.receivedCreatives.length
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
  },
  methods: {
    bytesToSize(bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      if (i === 0) return bytes + ' ' + sizes[i]
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
    },
    creativeType(type) {
      if (type === 2) {
        return 'MPEG4'
      } else if (type === 3) {
        return 'WEBM'
      }
    },
    actionGetCreatives() {
      this.$store.dispatch('creative/LOAD_CREATIVES')
    },
    convertDuration(duration) {
      const minutes = duration < 60 ? '00' : parseInt(duration / 60)
      let seconds = duration % 60 ? duration % 60 : '0'
      if (seconds < 10) { seconds = `0${seconds}` }
      return `${minutes}:${seconds}`
    },

    beforeUpload(file) {
      this.fileListValue = [...this.fileListValue, file]
      this.$emit('before-upload', { fileList: this.fileListValue })
      return false
    },
    emitImagePreview(val) {
      this.videoPreviewValue = val
      this.$emit('image-preview', { videoPreview: this.videoPreviewValue })
    },
    closePreview() {
      this.videoPreview = false
    },
    convertMediaRating(val) {
      if (val === 1) {
        return 'All Audiences'
      } else if (val === 2) {
        return 'Everyone Over 12'
      } else if (val === 3) {
        return 'Mature Audiences'
      } else {
        return undefined
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    editCreative(id) {
      // this.$router.push('/display/campaign/update/' + id)
      this.$router.push({ name: 'display-creative-update', params: { id: id } }).catch(() => { })
    },
    updateCreative(Id, Name, MediaRating) {
      MediaRating = parseInt(MediaRating)
      const payload = { Id, Name, MediaRating }
      updateCreative(payload)
    },
    handleChange(e) {
      //   console.log(e, 'EVENT')
      if (e.fileList.length === 1) {
        const selectedFile = e.file
        // console.log(selectedFile, 'Selektovan Fajl')
        createCreative(selectedFile, selectedFile.type)
          .then(response => {
            this.receivedCreatives.push(response.Creative)
            // console.log(this.receivedCreatives, 'list risivovanih')
          })
      } else if (e.fileList.length > 1) {
        const selectedFile = e.fileList[e.fileList.length - 1].originFileObj
        createCreative(selectedFile, selectedFile.type)
          .then(response => {
            this.receivedCreatives.push(response.Creative)
            // console.log(this.receivedCreatives, 'list risivovanih')
          })
      }
    },
  },
  watch: {
    fileList(val) {
      this.fileListValue = val
    },
    videoPreview(val) {
      this.videoPreviewValue = val
    },
  },
  created() {
    getCreatives().then(response => {
      response.forEach(el => {
        if (el.Mime === 2 || el.Mime === 3) {
          this.receivedCreatives.push(el)
        }
      })
    })
  },
}
</script>

<style lang="css" scoped>
.preview-screen {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100001;
  min-height: 100%;
  min-width: 100%;
}

.video-fade {
  -webkit-animation: fadein 0.45s;
  -moz-animation: fadein 0.45s;
  -ms-animation: fadein 0.45s;
  -o-animation: fadein 0.45s;
  animation: fadein 0.45s;
}

.cpointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .image-block {
    width: 170px !important;
    height: 170px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .picture-block {
    max-width: 170px !important;
    max-height: 170px !important;
  }

  .upload-container {
    display: flex;
    justify-content: space-between;
  }

  /* .input-width {
    width: 150px;
  } */
  .w-70 {
    width: 70% !important;
  }

  .w-30 {
    width: 35% !important;
  }

  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }

  .creatives-list {
    padding-left: none;
    padding-right: none;
  }

  li:last-child {
    border-bottom: none !important;
  }

  li:first-child {
    border-bottom: solid 1px #e8e8e8 !important;
  }

  .align-cell-center {
    display: flex;
    align-items: center;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .text-container {
    display: flex;
    flex-basis: 300px;
    font-size: 85%;
    justify-content: space-between;
  }

  .select-menu {}
}

@media only screen and (max-width: 768px) {
  .video-preview {
    width: 250px !important;
    height: auto !important;
  }

  .msg-box-flex {
    font-size: 75%;
  }
}

.msg-box-flex {
  display: flex;
  align-items: center;
  justify-content: start;
}

.msg-box {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 14px;
  margin-top: 15px;
  background-color: #fafafa;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #eae8e8;
}

.z-default {
  position: absolute;
  z-index: 0;
}

.z-above {
  position: relative;
  z-index: 1;
}</style>
